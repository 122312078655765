@import url(https://db.onlinewebfonts.com/c/6138beb83221f6eb46ed08543a304d1d?family=Twentieth+Century);
@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap");

@font-face {
  font-family: "Twentieth Century";
  src: url("https://db.onlinewebfonts.com/t/6138beb83221f6eb46ed08543a304d1d.eot");
  src: url("https://db.onlinewebfonts.com/t/6138beb83221f6eb46ed08543a304d1d.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/6138beb83221f6eb46ed08543a304d1d.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/6138beb83221f6eb46ed08543a304d1d.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/6138beb83221f6eb46ed08543a304d1d.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/6138beb83221f6eb46ed08543a304d1d.svg#Twentieth Century")
      format("svg");
}

@import url("./variables.css");

* {
  font-family: "Twentieth Century", "sans-serif";
  margin: 0;
  padding: 0;
}

a {
  color: rgb(44, 42, 42);
}

p {
  font-size: 18px;
  color: rgb(44, 42, 42);
}

h2 {
  font-size: 4em;
  line-height: 1;
}

h3 {
  font-size: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(44, 42, 42);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.container {
  width: 90%;
}

.primary-btn,
.mobile-btn {
  padding: 0.5em 1em;
  border: 2px solid var(--umber);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  width: fit-content;
  position: relative;
}

.primary-btn a,
.mobile-btn a {
  color: var(--umber);
  font-weight: 700;
  text-decoration: none;
  position: relative;
  z-index: 2;
  transition: all ease 0.5s;
}

.primary-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--umber);
  bottom: 0;
  left: 0;
  z-index: 1;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.primary-btn:hover::after {
  transform-origin: left;
  z-index: 1;
  transform: scaleX(1);
}

.primary-btn:hover a {
  color: white;
}

/* *primary button alt design */

.primary-btn__alt {
  padding: 0.5em 1em;
  border: 2px solid transparent;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  width: fit-content;
  position: relative;
  background-color: var(--umber);
}

.primary-btn__alt a {
  color: white;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  z-index: 2;
  transition: all ease 0.5s;
}

.primary-btn__alt::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  bottom: 0;
  left: 0;
  z-index: 1;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.primary-btn__alt:hover::after {
  transform-origin: left;
  z-index: 1;
  transform: scaleX(1);
}

.primary-btn__alt:hover a {
  color: var(--umber);
}

/* *primary button white design  */

.primary-btn__white {
  padding: 0.5em 1em;
  border: 2px solid transparent;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  width: fit-content;
  position: relative;
  background-color: transparent;
  border: 2px solid white;
}

.primary-btn__white a {
  color: white;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  z-index: 2;
  transition: all ease 0.5s;
}

/* .primary-btn__white::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--umber);
  bottom: 0;
  left: 0;
  z-index: 1;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.primary-btn__white:hover::after {
  transform-origin: left;
  z-index: 1;
  transform: scaleX(1);
}

.primary-btn__white:hover a {
  color: white;
} */

/* *secondary button */
.secondary-btn {
  padding: 0.7em 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all ease 500ms;
}

.secondary-btn a {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.secondary-btn:hover {
  border-color: var(--umber);
}

@media (max-width: 767px) {
  h2 {
    font-size: 3em;
  }

  h3 {
    font-size: 1.5em;
  }
}

@media (max-width: 300px) {
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1em;
  }
  .contact__section .container span {
    gap: 0em;
  }
}
