.breathwork__section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.breathwork__section .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.breathwork--row {
  display: flex;
  justify-content: space-between;
  margin-top: 6em;
}

.breathwork--image {
  width: 48%;
  display: flex;
  flex-direction: column;
}

.breathwork--image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.breathwork--image1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.breathworksecon-text {
  width: 100% !important;
  padding-top: 3em;
}
.breathwork--image1 img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.breathwork--text {
  width: 48%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1em;
}

.breathwork--banner {
  padding-bottom: 4em;
  margin-top: 8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: var(--light-bg); */
}

.breathwork--banner h2 {
  text-align: center;
}

.breathwork--banner__grid {
  padding: 4em 0;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 2em;
}

.breathwork--text__col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.breathwork--text__col ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.breathwork--text__col ul li p {
  display: flex;
  align-items: center;
  gap: 1.5em;
}

.breathwork--text__col ul li p i {
  color: var(--orange);
  font-weight: bold;
}

@media (max-width: 1168px) {
  .breathwork--text h2,
  .breathwork--banner h2 {
    font-size: 2.5em;
    margin-top: 1em;
  }
}

@media (max-width: 1168px) {
  .breathwork--banner {
    margin-top: 6em;
  }

  .breathwork--banner__grid {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
  }
}

@media (max-width: 971px) {
  .breathwork--banner__grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    gap: 1em;
  }
}

@media (max-width: 906px) {
  .breathwork--row {
    flex-direction: column;
  }

  .breathwork--image,
  .breathwork--image1,
  .breathwork--text {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .breathwork--banner__grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    gap: 1em;
    padding-bottom: 0;
  }
}
