.contact__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contact__section .container {
  padding: 8em 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contact__section .container span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.form-section {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-section form {
  width: 100%;
}

.form-section .form--grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  gap: 1em 2.5em;
  margin-top: 3em;
}

.form--grid .input-group {
  width: 100%;
}

.form--grid .input-group input,
textarea,
select {
  width: 100%;
  font-size: 1.2em;
  padding: 0.5em;
  border: 1px solid rgba(128, 128, 128, 0.29);
  outline: none;
}

textarea {
  margin-top: 1em;
}

.map,
.map iframe {
  width: 100%;
}

.hidden-placeholder {
  display: none !important;
}

@media (max-width: 605px) {
  .form-section .form--grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }

  .hidden-placeholder {
    display: block !important;
  }
}
